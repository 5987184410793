import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';

import { RolesContext } from 'src/Roles/RolesContext';
import { getFirstPathOn401RoleChange } from 'src/Roles/RolesService';
import IAlert from 'src/ServerEntities/IAlert';
import IServer from 'src/ServerEntities/IServer';
import IServerInformation from 'src/ServerEntities/IServerInformation';
import ISitePermissions from 'src/ServerEntities/ISitePermissions';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import createErrorMessage from 'src/UsefulFunctions/createErrorMessage';
import Alert from 'src/Views/Ram/Components/Alert';
import ServerDetails from 'src/Views/Ram/ServerDetails';
import ServerItem from 'src/Views/Ram/ServerItem';
import { SessionContext } from 'src/Views/SessionContext';
import { getServer } from './ramService';
import { filterAndSortUserAlerts } from './Utils/alertUtils';
import { AlertContainer, PrioritiesContainer } from './Alerts';

const MainContent = styled.main`
    display: flex;
    border-sytle: solid;
    flex-direction: column;
    flex: 1 0 auto;
    @media (min-width: 1279px) {
        padding: 24px;
    }
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    @media (max-width: 1279px) {
        flex-direction: column;
    }
`;

const SiteContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 10%;
    @media (min-width: 1279px) {
        padding: 24px;
    }
    @media (max-width: 1279px) {
        margin-top: 12px;
    }
`;

const ServersContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    margin-bottom: 24px;
`;

const AlertsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
`;

const SectionTitle = styled.h2`
    font-size: 1.1rem;
    font-weight: 500;
    margin: 0 0 8px;
`;

const ServerList = styled.ul`
    & li {
        margin: 0 0 8px;
        font-size: 0.9rem;
        background-color: #42a83f;
    }
    & li.with-alerts {
        background-color: #f99600;
    }
    & li.qvera-state-error {
        background-color: #f99600;
    }
    & li.without-heartbeat {
        background-color: #ff2121;
    }
`;

const StickyContainer = styled.div`
    @media (min-width: 1279px) {
        position: sticky;
        top: 10px;
    }
`;

interface IProps {
    selectedTypes: string[],
    viewUserIssues: boolean,
    changeRole: boolean,
    setChangeRole: (changeRole: boolean) => void,
    sortByDate: boolean
};

const alertToItem = (siteName: string, serverName: string, isSmall: boolean) => (alert: IAlert, index: number) => {
    return <AlertContainer key={`server-alert-item-${siteName}-${serverName}-${index}`}>
        <PrioritiesContainer>
            <div className={`priority-${alert.priority}`}>
                {alert.priority === 0 ? "" : alert.priority}
            </div>
        </PrioritiesContainer>
        <Alert
            alert={alert}
            closed={false}
            minimised={!isSmall}
        />
    </AlertContainer>;
};

const Sites = (props: IProps) => {
    const { serverName, siteName, namespace } = useParams<"serverName" | "siteName" | "namespace">();
    const { selectedTypes, viewUserIssues, changeRole, setChangeRole, sortByDate } = props;
    const { loggedInUser, webToken } = React.useContext(SessionContext).state;
    const [serverInformation, setServerInformation] = React.useState(null as unknown as IServerInformation);
    const [loading, setLoading] = React.useState(false);
    const [notesOpen, setNotesOpen] = React.useState(false);
    const [error, setError] = React.useState("");
    const [refresh, setRefresh] = React.useState(0);
    const roleContext = React.useContext(RolesContext);
    const navigate = useNavigate();

    const isSmall = useMediaQuery({
        query: '(max-width: 1279px)'
    });

    const serverToRow = (siteName: string, permissions: ISitePermissions) => (server: IServer, index: number) => {
        return <ServerItem sitePermissions={permissions} enlarge={false} displaySite={false} linkToServer={false} site={siteName} siteName={siteName} server={server} key={`site-${siteName}-server-${server.name}-${index}`} refresh={refresh} setRefresh={setRefresh} />;
    };

    const checkRoleChange = async (errorMessage: string) => {
        if (changeRole) {
            const path = await getFirstPathOn401RoleChange(errorMessage, webToken, roleContext.state.ramConfiguration?.components, roleContext.state.role)
            if (path) {
                navigate(path);
            }
            setChangeRole(false);
        }
    }

    React.useEffect(() => {
        if (!notesOpen) {
            setLoading(true);
            getServer(siteName || "", serverName || "", namespace || "", webToken, (serverView: IServerInformation) => {
                setLoading(false);
                setServerInformation(serverView);
                setError("");
                setChangeRole(false);
            }, (errorMessage: string) => {
                checkRoleChange(errorMessage);
                setLoading(false);
                setError(errorMessage);
                setServerInformation(null as unknown as IServerInformation);
            });
        }
    }, [serverName, siteName, namespace, webToken, notesOpen, refresh]);

    if (!serverInformation) {
        return <MainContent>
            {loading ? <LoadingIndicator type="Linear" show={loading} /> : <ErrorBox>Server {serverName} not found.</ErrorBox>}
        </MainContent>;
    }

    const currentFilteredServerAlerts = serverInformation.currentAlerts.filter(alert => selectedTypes.length === 0 || selectedTypes.includes(alert.type));
    const currentReducedAlerts = currentFilteredServerAlerts.reduce((previousArray, serverAlert) => previousArray.concat(serverAlert.alerts), [] as IAlert[]);
    const closedFilteredServerAlerts = serverInformation.closedAlerts.filter(alert => selectedTypes.length === 0 || selectedTypes.includes(alert.type));
    const closedReducedAlerts = closedFilteredServerAlerts.reduce((previousArray, serverAlert) => previousArray.concat(serverAlert.alerts), [] as IAlert[]);

    const currentUserAlerts = filterAndSortUserAlerts(currentFilteredServerAlerts, loggedInUser, sortByDate).reduce((previousArray, serverAlert) => previousArray.concat(serverAlert.alerts), [] as IAlert[]);
    const closedUserAlerts = filterAndSortUserAlerts(closedFilteredServerAlerts, loggedInUser, sortByDate).reduce((previousArray, serverAlert) => previousArray.concat(serverAlert.alerts), [] as IAlert[]);

    const selectedSite = serverInformation.site;

    const selectedServer = serverInformation.server
    const siteAlias = serverInformation.site.alias;

    return <MainContent>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{createErrorMessage("loading server information", error)}</ErrorBox>}
        <RowContainer>
            <ServerDetails
                closedAlerts={viewUserIssues ? closedUserAlerts : closedReducedAlerts}
                currentAlerts={viewUserIssues ? currentUserAlerts : currentReducedAlerts}
                ensembleInterfaces={serverInformation.ensembleInterfaces}
                ensembleCommPoints={serverInformation.server.commPoints}
                notesOpen={notesOpen}
                setNotesOpen={setNotesOpen}
                refresh={refresh}
                server={selectedServer}
                setRefresh={setRefresh}
                siteName={siteAlias || siteName || "Site Unknown"}
                siteType={selectedSite ? selectedSite.type : "UNK"}
                webToken={webToken}
                kubeInformation={serverInformation.server.kubeInformation}
                qveraInformation={serverInformation.server.qveraInformation}
            />
            <SiteContainer>
                <StickyContainer>
                    <ServersContainer>
                        <SectionTitle>All servers on this site</SectionTitle>
                        <ServerList>
                            {selectedSite.servers.map(serverToRow(selectedSite.site, selectedSite.permissions))}
                        </ServerList>
                    </ServersContainer>
                    <AlertsContainer>
                        <SectionTitle>Current issues on this site</SectionTitle>
                        <ul>
                            {viewUserIssues ? currentUserAlerts.map(alertToItem(siteName || "unknown", selectedServer.name, isSmall)) : currentReducedAlerts.map(alertToItem(siteName || "unknown", selectedServer.name, isSmall))}
                        </ul>
                    </AlertsContainer>
                </StickyContainer>
            </SiteContainer>
        </RowContainer>
    </MainContent>;
};

export default Sites;
