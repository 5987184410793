import * as React from "react";
import styled from "styled-components";

import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import handleKeyboardSelect from 'src/UsefulFunctions/handleKeyboardSelect';

type SortDirection = "ascending" | "descending";

const TableSortLabelContainer = styled.span<IProps>`
    cursor: pointer;
    align-items: center;
    color: ${props => props.active ? "#ffffff" : ""};
    user-select: none;
    &:focus {
        background-color: rgba(24,127,186,0.05);
        outline: 1px dotted #000000;
    }
`;

const FadeInWrapper = styled.span`
    transition: opacity 0.150s;
    opacity: 0;
    &.active {
        opacity: 1;
    }
`;

interface IProps {
    active: boolean,
    direction: SortDirection,
    onClick: (event: React.SyntheticEvent) => void,
    children: React.ReactNode
}

const TableSortLabel = (props: IProps) => {
    const { onClick, direction, children, active } = props;

    const style = {
        animation: `0.225s linear forwards`,
        marginLeft: "4px",
        marginRight: "4px",
        transform: direction === "descending" ? "rotate(180deg)" : "",
        transition: "transform 200ms"
    };

    return <TableSortLabelContainer tabIndex={0} onKeyDown={handleKeyboardSelect(onClick)} className="SortLabel" onClick={onClick} active={active} direction={direction}>
        {children}
        {active && <FadeInWrapper className={active ? "active" : ""}><FontAwesomeIcon style={style} icon={faArrowUp} /></FadeInWrapper>}
    </TableSortLabelContainer>;
}

export default TableSortLabel;
