import styled from 'styled-components';
import { useNavigate } from 'react-router';
import IRhapsodyDashboard from 'src/ServerEntities/IRhapsodyDashboard';
import { DateTime } from 'luxon';
import { HEARTBEAT_WARNING_LIMIT } from 'src/config';
import heartbeatBadIcon from 'src/images/customIcons/heartbeat-bad.png';
import heartbeatGoodIcon from 'src/images/customIcons/heartbeat-good.png';

interface IProps {
    data: IRhapsodyDashboard;
};

const ContentContainer = styled.main`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
`;

const OuterContainer = styled.div`
    border-radius: 25px;
    border: 5px solid #44A2D6;
    padding: 2px;
    height: 100%;
`;

const TitleBar = styled.div`
    border-radius: 20px;
    background-color: #1577AE;
    padding: 5px;
    color: #FFF;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    cursor: pointer;
`;

const InnerContainer = styled.div`
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
`;

const ComponentBar = styled.div`
    display: flex;
    width: 100%; 
    height: 40px;
    border: 0px solid black;
    border-radius: 20px;
    overflow: hidden;
    margin-top: 10px;
`;

const HearbeatBar = styled.div`
    width: 20%;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
`;

const NamespaceContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 10px;
    margin: 10px 0;
`;

const NamespaceItem = styled.div<{ isHealthy: boolean }>`
    display: flex;
    align-items: center;
    gap: 5px; /* Space between text and image */
    background-color: ${(props) => (props.isHealthy ? '#5CEA5A' : '#FF0000')}; 
    padding: 6px 12px;
    border-radius: 15px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap; /* Prevents wrapping */
`;

const NamespaceHeartbeatPanel = (props: IProps) => {
    const { data } = props;
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/Ram/Rhapsody Namespaces');
    };

    const isNamespaceHealthy = (namespaceTimestamp: number) => {
        return namespaceTimestamp > (DateTime.now().toMillis() - HEARTBEAT_WARNING_LIMIT);
    };

    return (
        <ContentContainer>
            <OuterContainer>
                <TitleBar onClick={handleClick}>Namespaces & Heartbeats</TitleBar>
                <InnerContainer>
                    <ComponentBar>
                        <HearbeatBar>
                            <div style={{ width: "32px", margin: "10px 0" }}></div>
                            {data.server.nameSpace && (
                                <NamespaceContainer>
                                    {Object.entries(data.server.nameSpace).map(([key, value]) => {
                                        const isHealthy = isNamespaceHealthy(value);
                                        return (
                                            <NamespaceItem key={key} isHealthy={isHealthy}>
                                                {key}
                                                <img height={28} width={28} src={isHealthy ? heartbeatGoodIcon : heartbeatBadIcon} alt="Heartbeat Status" />
                                            </NamespaceItem>
                                        );
                                    })}
                                </NamespaceContainer>
                            )}
                        </HearbeatBar>
                    </ComponentBar>
                </InnerContainer>
            </OuterContainer>
        </ContentContainer>
    );
};

export default NamespaceHeartbeatPanel;
