import IDisk from "src/ServerEntities/IDisk";
import { styled } from "styled-components";

const DiskUsageContainer = styled.div`
    width: 350px;
    border-radius: 25px;
    border: 5px solid #44A2D6;
    padding: 2px;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const PanelHeader = styled.div`
    width: 340px;
    border-radius: 20px;
    background-color: #1577AE;
    padding: 5px;
    color: #FFF;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    cursor: pointer;
    flex-shrink: 0;
`;

const DiskGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 10px;
`;

const DiskItem = styled.div<{ status: 'critical' | 'warning' | 'good' }>`
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: 0.9em;
    
    &::before {
      content: ${props => 
          props.status === 'critical' ? "'−'" :
          props.status === 'warning' ? "'!'" :
          "''"
      };
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 22px;
      border-radius: 50%;
      margin-right: 10px;
      font-weight: bold;
      font-size: 20px;
      color: white;
      padding: 1px 1px 4px;
      background-color: ${props => 
          props.status === 'critical' ? '#ff0000' :
          props.status === 'warning' ? '#ffa500' :
          '#5CEA5A'
      };
    }
`;

interface IProps {
  spaceMetrics: IDisk[]
}

const DiskUsagePanel = (props: IProps) => {
    const { spaceMetrics } = props
    const getStatus = (usagePercent: number) => {
        if (usagePercent >= 95) return 'critical';    
        if (usagePercent >= 80) return 'warning';    
        return 'good'; 
    };

    return (
        <DiskUsageContainer>
            <PanelHeader>Disk Usage</PanelHeader>
            <DiskGrid>
                {spaceMetrics.map((disk, index) => {
                    const percentUsed = 100 - parseFloat(disk.percentFree);
                    
                    return (
                        <DiskItem 
                            key={`${disk.computerName}-${disk.diskId}-${index}`}
                            status={getStatus(percentUsed)}
                        >
                            {disk.diskId}: {percentUsed}%
                        </DiskItem>
                    );
                })}
            </DiskGrid>
        </DiskUsageContainer>
    );
};

export default DiskUsagePanel;

