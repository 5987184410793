import ViewContainer from 'src/SharedComponents/ViewContainer';
import ManagerTitleBar from '../ManagerTitleBar';

import AlertNotifications from '../Components/AlertNotifications';
import HeartbeatNotifications from '../Components/HeartbeatNotifications';
import NotificationUsers from '../Components/NotificationUsers';
import VolumeNotifications from '../Components/VolumeNotifications';

interface IProps {
    refresh: number,
    tablabel: string
};

const SMSNotificationsTab = (props: IProps) => {
    const refresh = props.refresh;

    return <ViewContainer style={{ backgroundColor: "#fafafa", height: "80%" }}>
        <ManagerTitleBar viewName="Notifications" viewDescription="Here you can configure the notifications sent to support users" />
        <NotificationUsers refresh={refresh} />
        <HeartbeatNotifications />
        <AlertNotifications />
        <VolumeNotifications />
    </ViewContainer>;
};

export default SMSNotificationsTab;
