import React from 'react';
import 'react-toggle/style.css';

import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import { SessionContext } from 'src/Views/SessionContext';
import styled from 'styled-components';
import { getRhapsodyUsers } from '../ramService';
import { ContentContainer } from './RhapdosyDashboard';
import RhapsodyUserTable from './UserTable';
import IRhapsodyUser from 'src/ServerEntities/IRhapsodyUser';

interface IProps {
    selectedTypes: string[],
    changeRole: boolean,
    setChangeRole: (changeRole: boolean) => void
};

const SiteContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 20px;
    overflow: auto;
`;

const TieInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
`;

const TieTitle = styled.div`
    font-size: 1.3em;
    font-weight: bold;
`;

const TitleBar = styled.div`
    width: 1035px;
    border-radius: 20px;
    background-color: #1577AE;
    padding: 5px;
    padding-left: 10px;
    color: #FFF;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
`;

const RhapsodyUsers = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [users, setUsers] = React.useState(undefined as unknown as IRhapsodyUser[]);

    React.useEffect(() => {
        if (!users && !loading) {
            setLoading(true);
            getRhapsodyUsers(state.webToken, (data: IRhapsodyUser[]) => {
                setLoading(false);
                setUsers(data);
                setError("");
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }

    }, []);

    return <ContentContainer>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{error}</ErrorBox>}
        <TieInfo>
            <TieTitle>Rhapsody Integration Engine</TieTitle>
        </TieInfo>
        <SiteContainer>
            <TitleBar>Users</TitleBar>
            {users && <RhapsodyUserTable users={users} />}
        </SiteContainer>
    </ContentContainer>;
};

export default RhapsodyUsers;
