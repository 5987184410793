import * as React from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import styled from 'styled-components';

import useFocus from 'src/CustomHooks/useFocus';

import IHeaderInformation from 'src/ServerEntities/IHeaderInformation';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import ViewContainer from 'src/SharedComponents/ViewContainer';
import createErrorMessage from 'src/UsefulFunctions/createErrorMessage';
import Header from 'src/Views/Ram/Components/Header';
import RoleChangeDialog from 'src/Views/RoleChangeDialog';
import AlertDirectView from './AlertDirectView';
import Alerts from './Alerts';
import Hybrid from './Hybrid';
import Overview from './Overview';
import Server from './Server';
import SupportIssues from './SupportIssues';

import useEffectOnSome from 'src/CustomHooks/useEffectOnSome';
import { RolesContext } from 'src/Roles/RolesContext';
import IProcessThresholds from 'src/ServerEntities/IProcessThresholds';
import { SessionContext } from 'src/Views/SessionContext';
import { getProcessThresholds } from '../Manager/UserManagement/thresholdService';
import GenerateCodeDialog from '../MFA/GenerateCodeDialog';
import Clients from './Clients';
import { getCanCreateSupportIssue, getHeaderInformation } from './ramService';
import RhapsodyAlerts from './Rhapsody/Alerts';
import RhapsodyCertificates from './Rhapsody/RhapsodyCertificates';
import RhapsodyComponents from './Rhapsody/RhapsodyComponents';
import RhapsodyDashboard from './Rhapsody/RhapdosyDashboard';
import RhapsodyMenus from './Rhapsody/Menus';
import RhapsodyMessages from './Rhapsody/Messages';
import RhapsodyQueues from './Rhapsody/Queues';
import RhapsodyServers from './Rhapsody/Servers';
import RhapsodyUsers from './Rhapsody/Users';
import RoleDashboard from './RoleDashboard';
import SiteOverview from './SiteOverview';
import Sites from './Sites';
import IntersystemsDashboard from './Intersystems/IntersystemsDashboard';


const RamContainer = styled.div`
    background: #000000;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

const RhapsodyContainer = styled.div`
    background: #FFFFFF;
    color: #000000;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

const FooterBar = styled.footer`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    flex-wrap: wrap;

    & span {
        margin-bottom: 4px;
    }
`;

const FilterBanner = styled.div`
    background-color: #f99600;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 3px;
    
    p {
        font-weight: 700;
        margin: 0;
        margin-left: 12px; 
    }
`

const ToggleBar = styled.div`
    background-color: #19aae2;
    padding: 8px 8px 4px 48px;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 16px;
    flex-wrap: wrap;

    p {
        margin-right: 12px;
        font-weight: 700;
        margin: 0;
        margin-right: 32px;
    }

    @media (max-width: 1279px) {
        & p {
            display: none;
        }
    }
`;


const ToggleContainer = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 12px;

    label {
        margin-left: 6px;
    }
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const RhapsodyViewContainer = styled.div`
    background-color: #FFF;
    overflow: auto;
`;


const typeToToggle = (checked: boolean, onChangeChecked: (type: string) => () => void, type: string, selectedTypes: string[]) => {
    const id = `type-toggle-${type}`;
    return <ToggleContainer key={id}>
        <Toggle
            id={id}
            defaultChecked={checked}
            onChange={onChangeChecked(type)}
            checked={selectedTypes.includes(type)} />
        <label htmlFor={id}>{type}</label>
    </ToggleContainer>;
};

const emptyHeaderInformation = {
    sites: 0,
    servers: 0,
    unresponsiveServers: 0,
    unassignedAlerts: 0,
    alertsInProgress: 0,
    types: [] as unknown as string[],
    unprioritisedAlerts: 0
};

const getComponentForPageName = (pageName: string, selectedTypes: string[], viewUserIssues: boolean, setSelectedTypes: (type: string[]) => void, setChangeRole: (changeRole: boolean) => void, changeRole: boolean, sortByDate: boolean) => {
    switch (pageName) {
        case "Dashboard":
            return <SiteOverview selectedTypes={selectedTypes} changeRole={changeRole} setChangeRole={setChangeRole} />;
        case "RoleDashboard":
            return <RoleDashboard changeRole={changeRole} setChangeRole={setChangeRole} />;
        case "Overview":
            return <Overview selectedTypes={selectedTypes} changeRole={changeRole} setChangeRole={setChangeRole} />;
        case "Hybrid":
            return <Hybrid selectedTypes={selectedTypes} viewUserIssues={viewUserIssues} changeRole={changeRole} setChangeRole={setChangeRole} sortByDate={sortByDate} />;
        case "Alerts":
            return <Alerts selectedTypes={selectedTypes} viewUserIssues={viewUserIssues} changeRole={changeRole} setChangeRole={setChangeRole} sortByDate={sortByDate} />;
        case "Sites":
            return <Sites selectedTypes={selectedTypes} viewUserIssues={viewUserIssues} changeRole={changeRole} setChangeRole={setChangeRole} sortByDate={sortByDate} />;
        case "Clients":
            return <Clients changeRole={changeRole} setChangeRole={setChangeRole} />;
        case "Server":
            return <Server selectedTypes={selectedTypes} viewUserIssues={viewUserIssues} changeRole={changeRole} setChangeRole={setChangeRole} sortByDate={sortByDate} />;
        case "Alert":
            return <AlertDirectView />;
        case "Support":
            return <SupportIssues selectedTypes={selectedTypes} viewUserIssues={viewUserIssues} changeRole={changeRole} setChangeRole={setChangeRole} />;
        case "Rhapsody Dashboard":
            return <RhapsodyDashboard selectedTypes={selectedTypes} changeRole={changeRole} setChangeRole={setChangeRole} />;
        case "Rhapsody Components":
            return <RhapsodyComponents selectedTypes={selectedTypes} changeRole={changeRole} setChangeRole={setChangeRole} />;
        case "Rhapsody Servers":
            return <RhapsodyServers selectedTypes={selectedTypes} changeRole={changeRole} setChangeRole={setChangeRole} />;
        case "Rhapsody Alerts":
            return <RhapsodyAlerts selectedTypes={selectedTypes} changeRole={changeRole} setChangeRole={setChangeRole} />;
        case "Rhapsody Certificates":
            return <RhapsodyCertificates />;
        case "Rhapsody Queues":
            return <RhapsodyQueues selectedTypes={selectedTypes} changeRole={changeRole} setChangeRole={setChangeRole} />;
        case "Rhapsody Messages":
            return <RhapsodyMessages selectedTypes={selectedTypes} changeRole={changeRole} setChangeRole={setChangeRole} />;
        case "Rhapsody Users":
            return <RhapsodyUsers selectedTypes={selectedTypes} changeRole={changeRole} setChangeRole={setChangeRole} />;
        case "Intersystems Dashboard":
            return <IntersystemsDashboard selectedTypes={selectedTypes} changeRole={changeRole} setChangeRole={setChangeRole} />
        default:
            return <div />;
    }
};

const PAGES_WITH_ALERTS = ["Hybrid", "Alerts", "Sites", "Server"];

interface IProps {
    pageName: string
};

const RamHome = (props: IProps) => {
    const pageName = props.pageName;
    const { state, dispatch } = React.useContext(SessionContext);
    const ramConfiguration = React.useContext(RolesContext).state.ramConfiguration;
    const rhapsodyView = ramConfiguration ? ramConfiguration.rhapsodyView : false;
    const { webToken, processThresholds } = React.useContext(SessionContext).state;
    const [headerInformation, setHeaderInformation] = React.useState(emptyHeaderInformation);
    const storedTypes = localStorage.getItem("selectedTypes");
    const selectedTypesArray = storedTypes ? JSON.parse(storedTypes) : [];
    const [selectedTypes, setSelectedTypes] = React.useState(selectedTypesArray as string[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [roleChangeDialogOpen, setRoleChangeDialogOpen] = React.useState(false);
    const roleDialogRef = React.useRef(null as unknown as HTMLDivElement);
    const [userIssues, setUserIssues] = React.useState(false);
    const focus = useFocus(roleDialogRef);
    const footerRef = React.useRef<HTMLElement>(null);
    const [changeRole, setChangeRole] = React.useState(false);
    const [mfaDialogOpen, setMfaDialogOpen] = React.useState(false);
    const [sortByDate, setSortByDate] = React.useState(false);

    const openRoleChangeDialog = () => {
        setRoleChangeDialogOpen(true);
        focus();
    };

    const openMfaDialog = () => {
        setMfaDialogOpen(true);
        focus();
    };


    const closeMfaDialog = () => {
        setMfaDialogOpen(false);
    }

    const closeRoleChangeDialog = () => {
        setRoleChangeDialogOpen(false);
    };

    React.useEffect(() => {
        if (!rhapsodyView) { 
            setLoading(true);
            getHeaderInformation(state.webToken, (serverInformation: IHeaderInformation) => {
                setLoading(false);
                setHeaderInformation(serverInformation);
                setError("");
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });

            if (!processThresholds) {
                getProcessThresholds(webToken, (thresholds: IProcessThresholds) => {
                    setLoading(false);
                    dispatch({ type: "setProcessThresholds", payload: thresholds });
                    setError("");
                }, (errorMessage: string) => {
                    setLoading(false);
                    setError(errorMessage);
                });
            }
        }

    }, [state.webToken, state.processThresholds, processThresholds, webToken, dispatch, userIssues]);

    useEffectOnSome(() => {
        if (!rhapsodyView) {
            getCanCreateSupportIssue(webToken, (canCreate: boolean) => {
                dispatch({ type: "setCanRaiseSupport", payload: canCreate });
            }, (errorMessage: string) => {
                setError(errorMessage);
            });
            if (footerRef && footerRef.current) {
                const mainContainer = document.getElementsByClassName("App")[0] as HTMLElement;
                const newHeight = `calc(100% - ${footerRef.current.clientHeight}px)`;
                mainContainer.style.height = newHeight;
            }
        }
    }, [], [dispatch, webToken]);

    const changeSelectedTypes = (type: string) => () => {
        const typesCopy = [...selectedTypes];
        const index = typesCopy.indexOf(type);
        if (index >= 0) {
            typesCopy.splice(index, 1);
        } else {
            typesCopy.push(type);
        }
        setSelectedTypes(typesCopy);
        localStorage.setItem('selectedTypes', JSON.stringify(typesCopy));
    };

    const toggleUserIssues = () => {
        setUserIssues(!userIssues);
    };

    const toggleSortByDate = () => {
        setSortByDate(!sortByDate);
    };

    if (rhapsodyView) {
        const rhapsodyPageName = pageName.replace("Rhapsody ", "");
        return <RhapsodyViewContainer>
            <RoleChangeDialog dialogRef={roleDialogRef} open={roleChangeDialogOpen} onClose={closeRoleChangeDialog} setChangeRole={setChangeRole} applicationName="RAM" />
            <GenerateCodeDialog open={mfaDialogOpen} onClose={closeMfaDialog} />

            {/* Using two layers of View container to prevent an issue with positioning brought on by the role change dialog */}
            <RhapsodyContainer>
                <Header
                    pageName={rhapsodyPageName}
                    openRoleChangeDialog={openRoleChangeDialog}
                    headerInformation={headerInformation}
                    openMfaDialog={openMfaDialog}
                />

                <LoadingIndicator type="Linear" show={loading} />
                {error && <ErrorBox>{createErrorMessage("loading RAM sites", error)}</ErrorBox>}

                <MainContainer>
                    <RhapsodyMenus pageName={pageName} />
                    {getComponentForPageName(pageName, selectedTypes, userIssues, setSelectedTypes, setChangeRole, changeRole, sortByDate)}
                </MainContainer>
            </RhapsodyContainer>
        </RhapsodyViewContainer>;
    }
    else {
        return <ViewContainer>
            <RoleChangeDialog dialogRef={roleDialogRef} open={roleChangeDialogOpen} onClose={closeRoleChangeDialog} setChangeRole={setChangeRole} applicationName="RAM" />
            <GenerateCodeDialog open={mfaDialogOpen} onClose={closeMfaDialog} />

            {/* Using two layers of View container to prevent an issue with positioning brought on by the role change dialog */}
            <RamContainer>
                <Header
                    pageName={pageName}
                    openRoleChangeDialog={openRoleChangeDialog}
                    headerInformation={headerInformation}
                    openMfaDialog={openMfaDialog} />
                <LoadingIndicator type="Linear" show={loading} />
                {error && <ErrorBox>{createErrorMessage("loading RAM sites", error)}</ErrorBox>}
                {getComponentForPageName(pageName, selectedTypes, userIssues, setSelectedTypes, setChangeRole, changeRole, sortByDate)}
                <FooterBar>
                    {(selectedTypes.length > 0 || userIssues) && (<FilterBanner>
                        <p>Results filtered: showing {userIssues ? "your issues" : selectedTypes.map((type, index) => `${type}${index === selectedTypes.length - 1 ? '' : ', '}`).join('')} ONLY</p>
                    </FilterBanner>)}
                    <ToggleBar>
                        <p>Limit to:</p>
                        {headerInformation.types.map((type: string) => typeToToggle(selectedTypes.includes(type), changeSelectedTypes, type, selectedTypes))}
                        <ToggleContainer>
                            <Toggle
                                id={"users-issues"}
                                onChange={toggleUserIssues}
                                checked={userIssues} />
                            <label>Just my issues</label>
                        </ToggleContainer>
                    </ToggleBar>
                    {PAGES_WITH_ALERTS.includes(pageName) && <ToggleBar>
                        <p>Sort alerts by:</p>
                        <ToggleContainer>
                            <Toggle
                                id={"alert-order-issues-priority"}
                                onChange={toggleSortByDate}
                                checked={!sortByDate} />
                            <label>Priority</label>
                        </ToggleContainer>
                        <ToggleContainer>
                            <Toggle
                                id={"alert order-issues-date"}
                                onChange={toggleSortByDate}
                                checked={sortByDate} />
                            <label>Date</label>
                        </ToggleContainer>
                    </ToggleBar>}
                </FooterBar>
            </RamContainer>
        </ViewContainer>;
    }

};

export default RamHome;
